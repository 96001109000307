<template>
  <div style="background: #fff;height: 100%;
">
    <van-nav-bar style="background: #fff;" title="代朋友办理" left-arrow :fixed="true" :z-index="999" @click-left="back"></van-nav-bar>
    <div style="overflow: hidden;background: #fff;padding-top:45px;height: 100%;">
      <div class="hed">
        <div class="car-number">
          <van-row class="user_links2" style="border-bottom: 1px solid #dcdcdc;">
            <van-col span="5" class="chepeiib">车牌号码:</van-col>
            <van-col span="16" @click.stop="shurucp" style="float: right">
              <div class="item vux-1px-l" v-for="(v, i) in carNumberArr" :key="i">
                <div class="flex">{{carNumber[i] || ''}}</div>
              </div>
            </van-col>
          </van-row>
        </div>
        <van-row class="user_links" style="border-bottom: 1px solid #dcdcdc;">
          <van-col span="5" class="chepeii">手机号码:</van-col>
          <van-col span="18">
            <van-field v-model="addForm.phone" input-align="right" type="tel" :readonly="ermisok" placeholder="请输入手机号码" />
          </van-col>
        </van-row>
        <div class="qy_code_wrapper" v-if="ermisok">
          <vue-qr :text="link" :margin="10" colorDark="#000" colorLight="#fff" :dotScale="1" :logoSrc="img" :logoScale="0.2" :size="300"></vue-qr>
        </div>
        <div class="h_name" v-if="ermisok">微信扫码支付</div>
      </div>
    </div>
    <key-board v-show="showKeyBoard" @getPlateb="getPlateb" v-model="carNumber" :car_type="type"></key-board>
    <div class="boto" v-if="!ermisok">
      <van-button type="primary" v-preventReClick @click="shengchenewm">生成二维码由朋友扫码支付</van-button>
      <van-button type="danger" v-preventReClick @click="tijiao">代朋友付钱购买</van-button>
    </div>
  </div>
</template>

<script>
import userModel from "../../api/user";
import utils from "../../assets/utils";
import KeyBoard from "@/components/KeyBoard.vue";
import preventReClick from "../../assets/preventReClick";
import vueQr from "vue-qr";
export default {
  components: {
    vueQr,
    KeyBoard,
  },
  data() {
    return {
      gid: "",
      addForm: {},
      carNumberArr: ["", "", "", "", "", "", "", ""],
      carNumber: [],

      showKeyBoard: false,
      ermisok: false,
      type: "0",

      link: this.$route.query.link,
      userInfo: JSON.parse(localStorage.getItem("userInfo")),
      list: JSON.parse(sessionStorage.getItem("LBlist")),
      shangjia: JSON.parse(sessionStorage.getItem("LBshangjia")),
      uid: sessionStorage.getItem("uid"),
      aid: this.$route.query.aid,
      ssid: sessionStorage.getItem("ssid"),
      img: require("../../assets/img/yicheju.png"),
      ispay: false,
      qrcodes: null,
      isWxMini: "",
      timers: null,
      out_trade_no: "",
      uid: "",
    };
  },
  methods: {},
  created() {
    var isWxMini = window.__wxjs_environment === "miniprogram";
    this.isWxMini = isWxMini;
  },
  watch: {
    carNumber() {
      console.log(this.type);
      if (
        (this.type != 2 && this.carNumber.length === 8) ||
        this.carNumber.length === 8
      ) {
        this.showKeyBoard = false;
      }
    },
    dingshi: function (newVal, oldVal) {
      console.log(newVal);
      console.log(oldVal);
    },
    type() {
      if (this.type == 2) {
        if (this.carNumberArr.length == 7) {
          this.carNumberArr.push("");
          this.showKeyBoard = true;
        }
      } else if (this.carNumberArr.length == 8) {
        this.carNumberArr.pop();
        this.showKeyBoard = false;
      }
    },
  },
  methods: {
    shurucp() {
      console.log("asdasd");
      if (this.ermisok == false) {
        this.showKeyBoard = true;
      }
    },
    getPlateb() {
      this.showKeyBoard = false;
    },
    tijiao() {
      var carNo = this.carNumber.join("");
      var vm = this;
      if (carNo == "") {
        this.$toast("请先输入车牌号码！");
        return;
      }
      if (carNo.length < 7) {
        this.$toast("请输入正确的车牌号码！");
        return;
      }
      if (this.addForm.phone == "") {
        this.$toast("请先输入手机号码！");
        return;
      } else if (!/^1[23456789]\d{9}$/.test(this.addForm.phone)) {
        this.$toast("手机号码格式错误");
        return;
      }
      let data = {
        aid: this.aid,
        carModel: "",
        carNo: carNo,
        payAmount: this.list.activityPrice,
        offGid: this.shangjia.gid,
        gid: 208,
        phone: this.addForm.phone,
        sid: this.userInfo.userId,
        ssId: 0,
      };
      userModel.addCar(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          var isWxMini = window.__wxjs_environment === "miniprogram";
          console.log(isWxMini);
          if (this.list.activityPrice == 0) {
            var lis = {
              activityId: vm.aid,
              activityName: res.data.activityName,
              gid: res.data.gid,
              activityId: res.data.activityId,
              orderNumber: res.data.orderNumber,
              payAmount: res.data.payAmount,
              userId: res.data.userId,
              superiorId: res.data.superiorId,
            };
            userModel.payzero(lis).then((res) => {
              if (res.code == 200) {
                vm.$toast.success("办理成功");
                vm.addForm.phone = "";
                vm.carNumber = [];
                vm.carNumberArr = ["", "", "", "", "", "", "", ""];
              }
            });
          } else {
            var lis = {
              activityName: res.data.activityName,
              gid: res.data.gid,
              activityId: res.data.activityId,
              orderNumber: res.data.orderNumber,
              payAmount: res.data.payAmount,
              userId: res.data.userId,
              superiorId: res.data.superiorId,
              agentBuy: 1,
            };
            if (isWxMini) {
              lis.token = localStorage.getItem("hdtoken");
              let payDataStr = JSON.stringify(lis);
              wx.miniProgram.navigateTo({
                url: "/pages/pay/pay?payDataStr=" + payDataStr, // 微信小程序调用支付的页面
              });
            } else {
              userModel.pay(lis).then((res) => {
                if (res.code == 200) {
                  if (typeof WeixinJSBridge === "undefined") {
                    // 微信浏览器内置对象。参考微信官方文档
                    if (document.addEventListener) {
                      document.addEventListener(
                        "WeixinJSBridgeReady",
                        vm.onBridgeReady(res.data),
                        false
                      );
                    } else if (document.attachEvent) {
                      document.attachEvent(
                        "WeixinJSBridgeReady",
                        vm.onBridgeReady(res.data)
                      );
                      document.attachEvent(
                        "onWeixinJSBridgeReady",
                        vm.onBridgeReady(res.data)
                      );
                    }
                  } else {
                    console.log("准备调用微信支付");
                    vm.onBridgeReady(res.data);
                  }
                }
              });
            }
          }
        }
      });
    },
    onBridgeReady(data) {
      console.log("调用微信支付WeixinJSBridge");
      var vm = this;
      WeixinJSBridge.invoke(
        "getBrandWCPayRequest",
        {
          // 下面参数内容都是后台返回的
          appId: "wx406b62706ded5aa8", // 公众号名称，由商户传入
          timeStamp: data.timeStamp, // 时间戳
          nonceStr: data.nonceStr, // 随机串
          package: data.package, // 预支付id
          signType: data.signType, // 微信签名方式
          paySign: data.paySign, // 微信签名
        },
        function (res) {
          // 使用以上方式判断前端返回,微信团队郑重提示：res.err_msg将在用户支付成功后返回ok，但并不保证它绝对可靠。
          if (res.err_msg === "get_brand_wcpay_request:ok") {
            vm.$toast("下单成功");
            var carNo = vm.carNumber.join("");
            setTimeout(() => {
              vm.$router.push({
                path: "commission_xq",
                query: {
                  aid: vm.aid,
                  carNo: carNo,
                },
              });
            }, 800);
          } else {
            vm.$toast("下单失败");
          }
        }
      );
    },
    shengchenewm() {
      var carNo = this.carNumber.join("");
      var vm = this;
      if (carNo == "") {
        this.$toast("请先输入车牌号码！");
        return;
      }
      if (this.addForm.phone == "") {
        this.$toast("请先输入手机号码！");
        return;
      } else if (!/^1[23456789]\d{9}$/.test(this.addForm.phone)) {
        this.$toast("手机号码格式错误");
        return;
      }
      let data = {
        aid: this.aid,
        carModel: "",
        carNo: carNo,
        payAmount: this.list.activityPrice,
        offGid: this.shangjia.gid,
        gid: 208,
        phone: this.addForm.phone,
        sid: this.userInfo.userId,
        // sid: 3495,
        ssId: 0,
      };
      userModel.addCar(data).then((res) => {
        console.log(res);
        if (res.code == 200) {
          var lis = {
            activityName: res.data.activityName,
            gid: res.data.gid,
            activityId: res.data.activityId,
            orderNumber: res.data.orderNumber,
            payAmount: res.data.payAmount,
            userId: res.data.userId,
            superiorId: res.data.superiorId,
          };
          userModel.ewmpay(lis).then((res) => {
            if (res.code == 200) {
              console.log(res);
              this.link = res.data.code_url;
              this.out_trade_no = res.data.out_trade_no;
              this.getOrderstate(res.data.out_trade_no);
              this.ermisok = true;
            }
          });
        }
      });
    },
    getOrderstate(out_trade_no) {
      //判断支付是否成功，监听微信支付是否成功
      console.log(out_trade_no);
      let _this = this;
      let num = 0;
      _this.timers = setInterval(() => {
        num++;
        userModel.zhufusuccess({ out_trade_no: out_trade_no }).then((res) => {
          if (res.code == 200) {
            if (res.data.trade_state == "SUCCESS") {
              clearInterval(this.timers);
              this.ispay = false;
              this.$toast.success("下单成功");
              var carNo = _this.carNumber.join("");
              setTimeout(() => {
                _this.$router.push({
                  path: "commission_xq",
                  query: {
                    aid: _this.aid,
                    carNo: carNo,
                  },
                });
              }, 800);
            }
          }
        });
        if (num == 90) {
          (this.ispay = false),
            //别忘了清除定时器
            clearInterval(this.timers);
        }
      }, 2000);
    },
    back() {
      this.$router.go(-1);
    },
  },
  beforeDestroy() {
    //跳转页面时关闭定时器
    if (this.timers) {
      clearInterval(this.timers);

      // console.log('asdasdsad')
    }
  },
};
</script>
<style  lang="less" scoped>
#app {
  background: #f5f5f5;
  height: 100%;
}
.hed {
  background: #fff;
}
// --------------
.car-number {
  height: 50px;
}
.item {
  border: 1px solid #013bba;
  border-radius: 2px;
  width: 24px;
  height: 34px;
  line-height: 34px;
  text-align: center;
  float: left;
  margin-right: 5px;
}
.item:last-child {
  background: #4b84ff;
  border: 1px solid #013bba;
}
#key-board {
  position: fixed;
  bottom: 0;
  left: 0;
  z-index: 999;
}
.gzname {
  font-size: 12px;
  background: #fff;
  display: inline-block;
  padding: 1px 12px;
  color: #013bb9;
  border-radius: 6px;
  margin-bottom: 12px;
}
.gzname_a {
  font-size: 12px;
  color: #fff;
  margin-top: 10px;
}
.chepeiib {
  line-height: 34px;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}
.user_links2 {
  padding: 9px 0;
  font-size: 14px;
  margin-left: 10px;
  position: relative;
}
.user_links {
  padding: 12px 0;
  font-size: 14px;
  margin-left: 10px;
  position: relative;
  border-bottom: 1px solid #dcdcdc;
}
.chepeii {
  line-height: 22px;
  font-weight: bold;
  font-family: PingFangSC-Medium;
}
.van-cell {
  padding: 0;
  // background-color: #f1f1f1;
}
// ----------------------------
.qy_code_wrapper {
  margin-left: 12px;
  width: 300px;
  margin: auto;
  margin-top: 20px;
}
.qy_code_wrapper img {
  display: block;
  // text-align: center;
}
.h_name {
  text-align: center;
  color: #013bba;
  margin-top: 10px;
}
.xinbie {
  width: 14px;
  float: left;
  margin-top: 11px;
  margin-left: 8px;
}
.boto {
  position: fixed;
  bottom: 0;
  z-index: 99;
  left: 0;
  width: 100%;
  line-height: 40px;
  font-size: 12px;
}
.boto .van-button {
  width: 50%;
  float: left;
  border-radius: 0;
  padding: 0 6px;
}
</style>
